@import '~app/mixins';

$colorBorder: #ccc;
$shadow: inset 0 1px 0 0 #ececec;

.paymentMethod {
  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $color-grey;
  }

  .dropdown {
    select {
      color: #000;
      height: 30px;
      padding-left: 10px;
      border: 1px solid $colorBorder;
      border-radius: 5px;
    }

    &CardExpiration {
      display: inline-block;
      width: auto;
      width: 52px;

      &:last-child {
        margin-left: 8px;
        width: 70px;
      }
    }
  }

  .input {
    display: block;
    padding: 5px 8px;
    border: 1px solid $colorBorder;
    border-radius: 5px;
    box-shadow: $shadow;
    font-size: 14px;
    width: 100%;
  }

  .section {
    margin-bottom: 18px;

    .row {
      display: flex;
      margin-top: 8px;

      .field {
        width: 100%;

        + .field {
          margin-left: 8px;
        }
      }

      .width1 {
        width: 210px;
      }
    }
  }
}
